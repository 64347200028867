import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
    return (
        <><div id="block_container" className="header-container">
            <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>
        <div className="header-second-container">
                <a className="btn-light btn-sm" role="button" data-bss-hover-animate="pulse" style={{ background: '#3b515c', "&:hover":{background: '#2e577b'}, color: '#273f54', borderStyle: 'none', borderBottomStyle: 'none',}} href="https:\\bo.trouveuneplace.fr"><span style={{ color: '#ffffff', textDecoration: 'none', fontFamily: '"Allerta Stencil", sans-serif' }}>Espace Collectivité&nbsp;</span></a>
        </div></>
    );
}

export default Header
